import { tagOf } from "../../../classes/misc-util";
export function isMultiDropdownOptionElement(element) {
    return tagOf(element) === "dx-multi-dropdown-option";
}
export function isListItemElement(element) {
    return tagOf(element) === "dx-list-item";
}
export function isDropdownOptionElement(element) {
    return tagOf(element) === "dx-dropdown-option";
}
